<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Registro de Información de Proyectos Cooperación Norte-Sur
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="proyectos"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          noResultsText="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    filled
                    autocomplete="off"
                    dense
                    v-model="filtro"
                    label="Código / Nombre del proyecto"
                    maxlength="100"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.refSigeaci }}</td>
              <td>{{ item.fechaSolicitudOpinionTecnica }}</td>
              <td>{{ item.nombreProyecto }}</td>
              <td>
                {{ item.fechaInicioPlazos | formatDate }}<br />
                <span>{{ item.fechaFinalizacionPlazos | formatDate }}</span>

                <v-btn
                  v-if="
                    item.estadoProyectoId != 4 &&
                      new Date(currentDate) >
                        new Date(item.fechaFinalizacionPlazos)
                  "
                  color="deep-orange lighten-2"
                  class="ml-1"
                  @click="
                    mostrarDialogFechas(
                      item.codigo,
                      item.fechaFinalizacionPlazos
                    )
                  "
                  text
                  fab
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </td>

              <td>
                {{ item.nombreInstitucion }}<br />
                <span>{{ item.nombreUnidadEjecutora }}</span>
              </td>
              <td>
                <v-chip
                  label
                  class="ma-2"
                  :color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-4'
                      : item.estadoProyectoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-1'
                      : item.estadoProyectoId === 6
                      ? 'cyan darken-1'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadoProyecto }}
                </v-chip>

                <v-btn
                  v-if="
                    item.estadoProyectoId == 9 ||
                      item.estadoProyectoId == 5 ||
                      item.estadoProyectoId == 3
                  "
                  color="deep-orange lighten-2"
                  class="ml-1"
                  @click="mostrarDialogEsperandoEstado(item.codigo, item.nombreProyecto, item.estadoProyecto, item.estadoProyectoId)"
                  text
                  fab
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="
                    item.estadoProyectoId === 1 || item.estadoProyectoId === 2
                  "
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarModalActualizar(
                      item.id,
                      item.codigo,
                      item.refSigeaci
                    )
                  "
                >
                  <v-icon left>{{
                    !validacionTerritorio
                      ? item.estadoProyectoId === 1
                        ? `mdi-pencil`
                        : `mdi-eye`
                      : `mdi-eye`
                  }}</v-icon>
                  {{
                    !validacionTerritorio
                      ? item.estadoProyectoId === 1
                        ? `Actualizar información`
                        : `Consultar información`
                      : `Consultar información`
                  }}
                </v-btn>

                <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize d-none"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye</v-icon>
                </v-btn>

                <v-menu
                  v-if="!validacionTerritorio"
                  v-model="menuAvanceProyecto[item.id]"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      dark
                      small
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      @click="mostrarAvanceProyecto(item.id)"
                    >
                      <v-icon left>mdi-percent</v-icon> Avance
                    </v-btn>
                  </template>

                  <v-card class="elevation-0">
                    <div v-if="skeletonAvance[item.id]">
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-skeleton-loader
                            v-bind="attrs"
                            min-height="200px"
                            type="article, article"
                          >
                          </v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="!skeletonAvance[item.id]">
                      <v-list>
                        <v-list-item>
                          <!--<v-list-item-avatar>
                            <img
                              src="https://cdn.vuetifyjs.com/images/john.jpg"
                              alt="John"
                            >
                          </v-list-item-avatar>-->

                          <v-list-item-content>
                            <v-list-item-title
                              >Avance del Registro |
                              {{ item.codigo }}</v-list-item-title
                            >
                            <!--<v-list-item-subtitle>{{datosProyecto}}</v-list-item-subtitle>-->
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  informacionGeneralRegistrada
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  informacionGeneralRegistrada
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Información general del proyecto
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  alcanceRegistrado
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  alcanceRegistrado
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Alcance del proyecto
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  datosFinancierosRegistrados
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  datosFinancierosRegistrados
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Datos financieros
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  fechasPlazosRegistradas
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  fechasPlazosRegistradas
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Plazos del proyecto
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  coberturaRegistrada
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  coberturaRegistrada
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Cobertura y vinculación
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  beneficiariosRegistrados &&
                                  institucionesBeneficiariasRegistradas
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  beneficiariosRegistrados &&
                                  institucionesBeneficiariasRegistradas
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Beneficiarios del proyecto
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  documentosRegistrados
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  documentosRegistrados
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Documentos del proyecto
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <!--  <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoOpiniontecnicaRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoOpiniontecnicaRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento de opinión técnica
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoProyectoRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoProyectoRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento del proyecto
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item style="height: 25px; min-height: 25px;">
                            <v-list-item-content>
                              <v-list-item-subtitle class="text-subtitle-1">
                                <v-icon small :color="documentoConvenioRegistrado ? 'blue darken-2' : 'orange darken-2'">
                                  {{documentoConvenioRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                                </v-icon> Documento de convenio
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
 -->
                        <v-list-item style="height: 25px; min-height: 25px;">
                          <v-list-item-content>
                            <v-list-item-subtitle class="text-subtitle-1">
                              <v-icon
                                small
                                :color="
                                  contactosRegistrados
                                    ? 'blue darken-2'
                                    : 'orange darken-2'
                                "
                              >
                                {{
                                  contactosRegistrados
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                              Contactos
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-card>
                </v-menu>

                <v-btn
                  v-if="validacionTerritorio"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogConfirmacion(item.id, item.codigo)"
                >
                  <v-icon left>mdi-check-all</v-icon> Confirmar información de
                  territorio
                </v-btn>

                <v-btn
                  v-if="
                    item.estadoProyectoId === 1 &&
                      !validacionTerritorio &&
                      currentRoles.includes('SCO$INSTCNS')
                  "
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogConfirmacion(item.id, item.codigo)"
                >
                  <v-icon left>mdi-check-all</v-icon> Confirmar información
                </v-btn>

                <v-menu
                  v-if="item.estadoProyectoId === 2 && !validacionTerritorio"
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-eraser-variant</v-icon>Enmiendas
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="item.estadoProyectoId === 2"
                      :to="{
                        name: 'admin-enmiendas-fechas',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString()
                        }
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de fechas</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="item.estadoProyectoId === 2"
                      :to="{
                        name: 'admin-enmiendas-montos',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString()
                        }
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de montos</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="item.estadoProyectoId === 2"
                      :to="{
                        name: 'admin-enmiendas-componentes',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString()
                        }
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Enmiendas de componentes</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu
                  v-if="
                    !validacionTerritorio &&
                      (item.estadoProyectoId === 1 ||
                        item.estadoProyectoId === 2 ||
                        item.estadoProyectoId === 3 ||
                        item.estadoProyectoId === 4 ||
                        item.estadoProyectoId === 5)
                  "
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-format-list-bulleted</v-icon>Mas acciones
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="
                        item.estadoProyectoId === 1 ||
                          item.estadoProyectoId === 2 ||
                          item.estadoProyectoId === 3 ||
                          item.estadoProyectoId === 4 ||
                          item.estadoProyectoId === 5
                      "
                      :to="{
                        name: 'admin-poa',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString()
                        }
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Registro de Programación Anual de
                          proyecto</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="
                        item.estadoProyectoId === 2 ||
                        item.estadoProyectoId === 3 ||
                        item.estadoProyectoId === 4 ||
                        item.estadoProyectoId === 5"
                      :to="{
                        name: 'admin-iaff',
                        params: {
                          codigoProyecto: item.codigo,
                          proyectoId: $CryptoJS.AES.encrypt(
                            item.id.toString(),
                            'KEYADMINCNS2022'
                          ).toString()
                        }
                      }"
                    >
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Registro de Informe de Avance Físico y
                          Financiero</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu
                  v-if="item.estadoProyectoId === 2 && !validacionTerritorio"
                  bottom
                  offset-y
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize d-none"
                      color="blue-grey lighten-5"
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      dense
                      small
                    >
                      <v-icon left>mdi-file-pdf-box</v-icon>Reportes
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content append-icon="mdi-delete">
                        <v-list-item-title
                          >Reporte general de proyecto .pdf</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <v-dialog
      v-model="dialogRegistro"
      persistent
      max-width="1280px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <div class="row mt-0 mb-0">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          Registro de proyecto | {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-2">
              Información General
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-1">
              Alcance
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Datos Financieros y Proy
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5">
              Cobertura y Vinculación
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7">
              Carga de documentos
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-8">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-9" @click="actualizarAvanceProyecto(idProyecto)">
              Confirmar información
              <v-icon>mdi-check-all</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionInformacionGeneralProyecto
                    ref="seccionInformacionGeneral"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @seccion-condiciones-desembolsos="
                      cuentaCondicionesDesembolsos
                    "
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionAlcanceProyecto
                    ref="seccionAlcance"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionDatosFinancierosProyecto
                    ref="seccionDatosFinancieros"
                    :id="idProyecto"
                    :codSigeaci="refSigeaci"
                    @actualizar-ejecucion-subdivsion-cobertura="
                      actualizarEjecucionSubdivisionTerritorio
                    "
                    @habilitar-cobertura-territorial="
                      habilitarCoberturaTerritorial
                    "
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @obtenerCoberturaTerritoralVinculacion="
                      actualizarEjecucionSubdivisionTerritorio(idProyecto)
                    "
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <SeccionPlazosProyecto
                    ref="seccionPlazos"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <SeccionCoberturaVinculacion
                    ref="seccionCoberturaVinculacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @obtener-cobertura-territorial-vinculacion="
                      obtenerMontosSuscritosDepto(idProyecto)
                    "
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <SeccionBeneficiariosProyecto
                    ref="seccionBeneficiariosProyecto"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <SeccionDocumentosProyecto
                    ref="seccionDocumentos"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <SeccionContactosProyectoCns
                    ref="seccionContactos"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                    :iaff="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-9'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <SeccionConfirmarProyecto
                    ref="SeccionConfirmarProyecto"
                    :id="idProyecto"
                    :tipoUsuario="`int`"
                    :confirmacion="false"
                    @actualizar-avance-proyecto="actualizarAvanceProyecto"
                    @obtener-proyectos="obtenerProyectos"
                    @mostrar-dialog-confirmar="mostrarDialogConfirmacion"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="1200"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-tabs
            v-model="tabConfirmacion"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-2">
              Información General del Proyecto
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-1">
              Alcance del proyecto
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Datos Financieros y Proyección
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Plazos del proyecto
              <v-icon>mdi-av-timer</v-icon>
            </v-tab>

            <v-tab href="#tab-5">
              Cobertura y Vinculación
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-6">
              Beneficiarios
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-7">
              Carga de documentos
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-8">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabConfirmacion" touchless>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          informacionGeneralRegistrada
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          informacionGeneralRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p
                          v-if="informacionGeneralRegistrada"
                          class="mt-1 mb-1"
                        >
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionInformacionGeneralProyecto
                    ref="seccionInformacionGeneralConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          alcanceRegistrado
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          alcanceRegistrado
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="alcanceRegistrado" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <SeccionAlcanceProyecto
                    ref="seccionAlcanceConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          datosFinancierosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          datosFinancierosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="datosFinancierosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionDatosFinancierosProyecto
                    ref="seccionDatosFinancierosConfirmacion"
                    :id="idProyecto"
                    :codSigeaci="refSigeaci"
                    @actualizar-ejecucion-subdivsion-cobertura="
                      actualizarEjecucionSubdivisionTerritorio
                    "
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    @obtenerCoberturaTerritoralVinculacion="
                      actualizarEjecucionSubdivisionTerritorio(idProyecto)
                    "
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          fechasPlazosRegistradas
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          fechasPlazosRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="fechasPlazosRegistradas" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionPlazosProyecto
                    ref="seccionPlazosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7;">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          coberturaRegistrada
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          coberturaRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="coberturaRegistrada" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionCoberturaVinculacion
                    ref="seccionCoberturaVinculacionConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    @obtener-cobertura-territorial-vinculacion="
                      obtenerMontosSuscritosDepto(idProyecto)
                    "
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-6'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p
                          v-if="
                            beneficiariosRegistrados &&
                              institucionesBeneficiariasRegistradas
                          "
                          class="mt-1 mb-1"
                        >
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionBeneficiariosProyecto
                    ref="seccionBeneficiariosProyectoConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-7'">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          documentosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          documentosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="documentosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionDocumentosProyecto
                    ref="seccionDocumentosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-8'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-alert
                        outlined
                        dense
                        :icon="
                          contactosRegistrados
                            ? 'mdi-check-all'
                            : 'mdi-alert-circle-outline'
                        "
                        :color="
                          contactosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                        border="left"
                      >
                        <p v-if="contactosRegistrados" class="mt-1 mb-1">
                          Información completa
                        </p>
                        <p v-else class="mt-1 mb-1">
                          Existe información pendiente de ingresar en esta
                          sección.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <SeccionContactosProyectoCns
                    ref="seccionContactosConfirmacion"
                    :id="idProyecto"
                    :tipoUsuario="`ext`"
                    :confirmacion="true"
                    :iaff="false"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <v-row class="mt-2">
            <v-col cols="12">
              <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                Existe información pendiente de registrar para el proyecto
                {{ codigoProyectoCNS }}.
              </p>
              <p class="text-subtitle-1 mb-1 pb-0" v-if="datosPendientes > 0">
                Por favor, complete la información e inténtelo nuevamente
              </p>
              <p class="text-subtitle-1" v-if="datosPendientes > 0">
                Información pendiente de registrar:
              </p>

              <v-list>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          informacionGeneralRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          informacionGeneralRegistrada
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Información General
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          alcanceRegistrado
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          alcanceRegistrado
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Alcance
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          datosFinancierosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          datosFinancierosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Datos financieros
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          fechasPlazosRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          fechasPlazosRegistradas
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Plazos del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          coberturaRegistrada
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          coberturaRegistrada
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Cobertura y vinculación
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          beneficiariosRegistrados &&
                          institucionesBeneficiariasRegistradas
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Beneficiarios del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          documentosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          documentosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Carga de Documentos del proyecto
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!--  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoOpiniontecnicaRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoOpiniontecnicaRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento de opinión técnica
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoProyectoRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoProyectoRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento del proyecto
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="height: 25px; min-height: 25px;">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-1">
                        <v-icon small :color="documentoConvenioRegistrado ? 'blue darken-2' : 'orange darken-2'">
                          {{documentoConvenioRegistrado ? 'mdi-check-all' : 'mdi-alert-circle-outline'}}
                        </v-icon> Documento de convenio
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
-->
                <v-list-item style="height: 25px; min-height: 25px;">
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-icon
                        small
                        :color="
                          contactosRegistrados
                            ? 'blue darken-2'
                            : 'orange darken-2'
                        "
                      >
                        {{
                          contactosRegistrados
                            ? "mdi-check-all"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      Contactos
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  class="mt-4"
                  style="height: 25px; min-height: 25px;"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-subtitle-1">
                      <v-chip
                        class="ma-2"
                        label
                        :color="
                          porcentajeAvance == 100
                            ? `light-blue lighten-2`
                            : porcentajeAvance >= 0 && porcentajeAvance <= 10
                            ? `red lighten-2`
                            : porcentajeAvance < 10 && porcentajeAvance <= 30
                            ? `deep-orange lighten-2`
                            : porcentajeAvance < 30 && porcentajeAvance <= 50
                            ? `orange lighten-2`
                            : porcentajeAvance < 50 && porcentajeAvance <= 70
                            ? `yellow lighten-2`
                            : porcentajeAvance < 70 && porcentajeAvance <= 90
                            ? `lime lighten-2`
                            : porcentajeAvance < 90 && porcentajeAvance <= 99
                            ? `green lighten-2`
                            : `blue`
                        "
                      >
                        {{ porcentajeAvance }}% información registrada
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- {{datosProyecto}}-->

              <p class="text-subtitle-1 mt-2" v-if="datosPendientes <= 0">
                ¿Está seguro de confirmar la información del proyecto
                {{ codigoProyectoCNS }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <!--<v-divider></v-divider>-->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarProyecto"
            :loading="btnEnvioLoading"
            :disabled="porcentajeAvance < 100"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--dialog fehcas-->
    <v-dialog
      v-model="dialogFechas"
      persistent
      max-width="500px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <!--  <div class="row mt-0 mb-0">
            <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
              <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
            </div>
        </div> -->
        <v-card-title>
          Advertencia fechas | {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogFechas = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="text-h6">
                El proyecto ({{ codigoProyecto }}) tiene una fecha de
                finalización ({{ fechaFinalizacionProyecto | formatDate }})
                anterior a la fecha actual, por favor, revise si existen
                acciones pendientes de aplicar en SICOOPERAGT.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--inicio:: dialog alerta estado-->
    <v-dialog 
      v-model="dialogEsperandoEstado"
      persistent
      max-width="500px"
      transition="scroll-y-transition"
      scrollable
    >
    <v-card tile>
        <!--  <div class="row mt-0 mb-0">
            <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
              <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
            </div>
        </div> -->
        <v-card-title>
          Información | {{ codigoProy }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogEsperandoEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="text-h6">
                El proyecto ({{ codigoProy }}) - {{ nombreProy }} se encuentra
                en espera de la aprobación de solicitud de cambio de estado
                realizada.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: dialog alerta estado-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
//import JwtService from "@/core/services/jwt.service";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

import {
  OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION,
  CONFIRMAR_PROYECTO_CNS,
  OBTENER_DATOS_SECCIONES_PROYECTO,
  OBTENER_AVANCE_PROYECTO_CNS
} from "@/core/services/store/proyectoscns/proyectocns.module";

import validations from "@/core/untils/validations.js";

import SeccionAlcanceProyecto from "@/view/pages/proyectos-cns/externo/SeccionAlcanceProyecto.vue";
import SeccionInformacionGeneralProyecto from "@/view/pages/proyectos-cns/externo/SeccionInformacionGeneralProyecto.vue";
import SeccionDocumentosProyecto from "@/view/pages/proyectos-cns/externo/SeccionDocumentosProyecto.vue";

import SeccionDatosFinancierosProyecto from "@/view/pages/proyectos-cns/externo/SeccionDatosFinancierosProyecto.vue";
import SeccionPlazosProyecto from "@/view/pages/proyectos-cns/externo/SeccionPlazosProyecto.vue";
import SeccionCoberturaVinculacion from "@/view/pages/proyectos-cns/externo/SeccionCoberturaVinculacion.vue";
import SeccionBeneficiariosProyecto from "@/view/pages/proyectos-cns/externo/SeccionBeneficiariosProyecto.vue";
import SeccionContactosProyectoCns from "@/view/pages/proyectos-cns/externo/SeccionContactosProyectoCns.vue";
import SeccionConfirmarProyecto from "@/view/pages/proyectos-cns/externo/SeccionConfirmarProyecto.vue";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "AdminInformacionProyectosCns",
  props: ["validacionTerritorio"],
  components: {
    SnackAlert,
    DialogLoader,
    SeccionAlcanceProyecto,
    SeccionInformacionGeneralProyecto,
    SeccionDocumentosProyecto,
    SeccionDatosFinancierosProyecto,
    SeccionPlazosProyecto,
    SeccionCoberturaVinculacion,
    SeccionBeneficiariosProyecto,
    SeccionContactosProyectoCns,
    SeccionConfirmarProyecto,
    DivisorColor
  },
  data() {
    return {
      dateNow: moment(),
      fechaFinalizacionProyecto: "",
      dialogFechas: false,
      currentDate: "",
      validForm: false,
      ddRolesLoading: false,
      switchItemEstado: true,
      dialogRegistro: false,
      dialogConfirmacion: false,
      btnEnvioLoading: false,
      tableLoading: false,
      dialogSuspension: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo información del proyecto...",
      skeletonAvance: [],
      tab: null,
      tabConfirmacion: "tab-2",
      proyectoSeleccionado: "",
      idProyectoSeleccionado: 0,
      filtro: "",
      dialog: false,
      modalTitle: "Registrar nuevo proyecto",
      accion: 1,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      proyectos: [],
      idProyecto: 0,
      codigoProyectoCNS: "",
      codigoProyecto: "",
      refSigeaci: "",
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "Registro" },
        { value: 2, text: "Seguimiento" },
        { value: 6, text: "Finalizado" },
        { value: 7, text: "Suspendido" }
      ],

      menuAvanceProyecto: [{ id: 1, show: false }],
      datosProyecto: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0
      },
      datosAvanceProyecto: {},
      informacionGeneralRegistrada: false,
      alcanceRegistrado: false,
      contactosRegistrados: false,
      datosFinancierosRegistrados: false,
      fechasPlazosRegistradas: false,
      beneficiariosRegistrados: false,
      institucionesBeneficiariasRegistradas: false,
      coberturaRegistrada: false,
      documentosRegistrados: false,
      documentoJustificacionRegistrado: false,
      documentoOpiniontecnicaRegistrado: false,
      documentoProyectoRegistrado: false,
      documentoConvenioRegistrado: false,
      datosPendientes: 0,
      porcentajeAvance: 0,
      porcentajeDatosFinancieros: 0,
      codigoProy: "",
      nombreProy: "",
      estadoProy: "",
      dialogEsperandoEstado: false,
      ...validations
    };
  },

  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.value;
    },

    //Obtener proyectos
    async obtenerProyectos() {
      //let token = JwtService.getToken();
      this.proyectos = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION, { estadoId: 0 })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    mostrarModalActualizar(id, codigo, refSigeaci) {
      this.idProyecto = id;
      this.dialogLoaderText = "Obteniendo información...";
      this.dialogLoaderVisible = true;

      this.tab = "tab-2";
      //console.log(this.idProyecto)
      //console.log(this.idProyecto)
      this.codigoProyecto = codigo;
      this.refSigeaci = refSigeaci;
      //console.log(test);
      if (this.$refs.seccionInformacionGeneral) {
        this.$refs.seccionInformacionGeneral.resetItems(2, id);

        /*this.$refs.seccionInformacionGeneral.obtenerInstituciones().then(() => {
          this.$refs.seccionInformacionGeneral.obtenerTiposFuenteCooperante();

          this.$refs.seccionInformacionGeneral
            .obtenerFuentesBilaterales()
            .then(() => {
              this.$refs.seccionInformacionGeneral
                .obtenerFuentesMultilateral()
                .then(() => {
                  this.$refs.seccionInformacionGeneral.obtenerDatosItem(
                    this.idProyecto
                  );
                });
            });
        });*/
      }

      if (this.$refs.seccionAlcance) {
        this.$refs.seccionAlcance.resetItems(id);
        //this.$refs.seccionAlcance.obtenerDatosItem(this.idProyecto);
      }

      if (this.$refs.seccionDatosFinancieros) {
        this.$refs.seccionDatosFinancieros.resetItems(2, id);
        /*this.$refs.seccionDatosFinancieros.obtenerTiposDatosFinancieros();
        this.$refs.seccionDatosFinancieros.obtenerTiposDatosFinancierosDistribucion();
        this.$refs.seccionDatosFinancieros.obtenerDatosEspecificosProyecto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.resetItems();
        this.$refs.seccionDatosFinancieros.obtenerMecanismosProyecto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerDatosFinancieros(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerDatosDistribucionFuenteCooperante(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerProyeccionesDesembolsos(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerMontosSuscritosDepto(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividad(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividadContrapartida(
          this.idProyecto
        );
        this.$refs.seccionDatosFinancieros.obtenerSubDivisiones();

        this.$refs.seccionDatosFinancieros.obtenerReferenciasSubdivisionFC(
          this.refSigeaci
        );
        this.$refs.seccionDatosFinancieros.obtenerReferenciasSubdivisionCP(
          this.refSigeaci
        );
        this.$refs.seccionDatosFinancieros.obtenerEjecucionesPorActividadDeptoMuni(
          this.idProyecto
        );*/
      }

      if (this.$refs.seccionPlazos) {
        this.$refs.seccionPlazos.resetItems(id);
        /*this.$refs.seccionPlazos.obtenerDatosItem(this.idProyecto);
        this.$refs.seccionPlazos.obtenerCondicionesDesembolsos(this.idProyecto);
        this.$refs.seccionPlazos.obtenerTiposCondicionesDesembolso();*/
      }

      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.resetItems(1, id);

        /*this.$refs.seccionCoberturaVinculacion
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionCoberturaVinculacion.obtenerCoberturaProyecto(
              this.idProyecto
            );
            this.$refs.seccionCoberturaVinculacion.obtenerMontosSuscritosDepto(
              this.idProyecto
            );
          });

        this.$refs.seccionCoberturaVinculacion.obtenerSectoresBeneficiados();
        this.$refs.seccionCoberturaVinculacion.obtenerDepartamentos();
        this.$refs.seccionCoberturaVinculacion.obtenerPnds();
        this.$refs.seccionCoberturaVinculacion.obtenerOds();
        this.$refs.seccionCoberturaVinculacion.obtenerEjesKatun();
        this.$refs.seccionCoberturaVinculacion.obtenerPgg();
        //this.$refs.seccionCoberturaVinculacion.obtenerActividades();

        //this.$refs.seccionCoberturaVinculacion.obtenerCad();
        this.$refs.seccionCoberturaVinculacion.obtenerPoliticasPublicas();*/
      }

      if (this.$refs.seccionBeneficiariosProyecto) {
        this.$refs.seccionBeneficiariosProyecto.resetItems(1, id);
        /*this.$refs.seccionBeneficiariosProyecto.obtenerDatosSeccion(
          this.idProyecto
        );
        this.$refs.seccionBeneficiariosProyecto.obtenerBeneficiarios(
          this.idProyecto
        );
        this.$refs.seccionBeneficiariosProyecto.obtenerBeneficiariosInstitucion(
          this.idProyecto
        );

        this.$refs.seccionBeneficiariosProyecto.obtenerGeneros();
        this.$refs.seccionBeneficiariosProyecto.obtenerAreasBeneficiadas();
        this.$refs.seccionBeneficiariosProyecto.obtenerEtnias();
        this.$refs.seccionBeneficiariosProyecto.obtenerRangosEdad();
        this.$refs.seccionBeneficiariosProyecto.obtenerTiposIngreso();*/
      }

      if (this.$refs.seccionDocumentos) {
        this.$refs.seccionDocumentos.resetItems(2, id);

        /*  this.$refs.seccionDocumentos
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionDocumentos.obtenerDocumentosCargados(
              this.idProyecto
            );
          }); */
        //this.$refs.seccionDocumentos.obtenerDocumentosCns();
        //this.$refs.seccionDocumentos.obtenerDocumentosCargados(this.idProyecto,1 );
      }

      if (this.$refs.seccionContactos) {
        this.$refs.seccionContactos.resetItems(1, id);
        /* this.$refs.seccionContactos
          .obtenerDatosGeneralesProyecto(this.idProyecto)
          .then(() => {
            this.$refs.seccionContactos.resetItems(1);
            this.$refs.seccionContactos.obtenerTiposContacto();
            this.$refs.seccionContactos.obtenerContactos(this.idProyecto);
          }); */
      }

      if (this.$refs.SeccionConfirmarProyecto) {
        this.$refs.SeccionConfirmarProyecto.obtenerAvanceProyectoCns(
          this.idProyecto
        );
      }

      this.dialogLoaderVisible = false;
      this.dialogRegistro = true;
    },

    async mostrarAvanceProyecto(idProyecto) {
      this.datosProyecto = [];
      this.skeletonAvance[idProyecto] = true;
      this.obtenerAvanceProyectoCns(idProyecto).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.skeletonAvance[idProyecto] = false;
      });
      /* this.obtenerDatosEspecificosProyecto(idProyecto).then(()=>{
         this.verificarDatosSecciones(this.datosProyecto);
         this.skeletonAvance[idProyecto] = false;
      });*/
    },

    ///Obener el avance de registro del proyecto
    async obtenerAvanceProyectoCns(proyectoId) {
      this.datosAvanceProyecto = {};
      await this.$store
        .dispatch(OBTENER_AVANCE_PROYECTO_CNS, { id: proyectoId })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosAvanceProyecto = res.data;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Mensaje",
              res.message
            );
          }
          this.skeletonAvance[proyectoId] = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonAvance[proyectoId] = false;
          this.dialogLoaderVisible = false;
          this.datosAvanceProyecto = {};
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerDatosEspecificosProyecto(idProyecto) {
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosProyecto = res.data;
          }
          this.skeletonAvance[idProyecto] = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonAvance[idProyecto] = false;
          this.dialogLoaderVisible = false;
          this.datosProyecto = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    /*actualizarEjecucionSubdivisionTerritorio(){
      //console.log("aqui.........")
      if(this.$refs.seccionCoberturaVinculacion){
        this.$refs.seccionCoberturaVinculacion.obtenerMontosSuscritosDepto(this.idProyecto);
      }
    },*/

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
      this.tab = "tab-2";
      this.$refs.ModalRegistro.ocultarModal();
      let test = this.$options.components["SeccionInformacionGeneralProyecto"];
      test.$destroy();
    },

    resetForm() {},
    ///Registro de usuario
    registrarItem() {
      return "Listo";
    },
    submit: function(e) {
      e.preventDefault();
      this.$refs.snackalert.SnackbarShow("success", "Mensaje", "test");
    },

    mostrarDialogConfirmacion(idProyecto, codigo) {
      //console.log(idProyecto)
      //console.log(codigo)
      this.idProyecto = idProyecto;
      this.idProyectoSeleccionado = idProyecto;
      this.dialogLoaderVisible = true;
      this.codigoProyectoCNS = codigo;
      this.datosProyecto = [];
      this.tabConfirmacion = "tab-2";

      if (this.$refs.seccionInformacionGeneralConfirmacion) {
        this.$refs.seccionInformacionGeneralConfirmacion.resetItems(2);
      }

      if (this.$refs.seccionAlcanceConfirmacion) {
        this.$refs.seccionAlcanceConfirmacion.resetItems();
        this.$refs.seccionAlcanceConfirmacion.obtenerDatosItem(this.idProyecto);
      }

      if (this.$refs.seccionDatosFinancierosConfirmacion) {
        this.$refs.seccionDatosFinancierosConfirmacion.resetItems(
          2,
          this.idProyecto
        );
      }

      if (this.$refs.seccionPlazosConfirmacion) {
        this.$refs.seccionPlazosConfirmacion.resetItems(this.idProyecto);
        /*this.$refs.seccionPlazos.obtenerDatosItem(this.idProyecto);
        this.$refs.seccionPlazos.obtenerCondicionesDesembolsos(this.idProyecto);
        this.$refs.seccionPlazos.obtenerTiposCondicionesDesembolso();*/
      }

      if (this.$refs.seccionCoberturaVinculacionConfirmacion) {
        this.$refs.seccionCoberturaVinculacionConfirmacion.resetItems(1);
      }

      if (this.$refs.seccionBeneficiariosProyectoConfirmacion) {
        this.$refs.seccionBeneficiariosProyectoConfirmacion.resetItems(1);
      }

      if (this.$refs.seccionDocumentosConfirmacion) {
        this.$refs.seccionDocumentosConfirmacion.resetItems(2);
      }

      if (this.$refs.seccionContactosConfirmacion) {
        this.$refs.seccionContactosConfirmacion.resetItems(1);
      }

      this.obtenerAvanceProyectoCns(idProyecto).then(() => {
        this.verificarDatosSecciones(this.datosAvanceProyecto);
        this.dialogLoaderVisible = false;
        this.dialogConfirmacion = true;
      });
    },

    //Verificar los datos necesarios para poder confirmar el proyecto y devolver un listado
    verificarDatosSecciones(datosProyecto) {
      this.porcentajeDatosFinancieros = 0;
      this.datosPendientes = 0;
      this.porcentajeAvance = 0;
      this.porcentajeAvance += 10;

      //this.informacionGeneralRegistrada = true;

      //Datos de la seccion información General del proyecto
      if (datosProyecto.informacionGeneral > 0) {
        this.informacionGeneralRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.informacionGeneralRegistrada = true;
        this.datosPendientes++;
      }

      //Datos de la seccion alcance del proyecto
      if (datosProyecto.alcance > 0) {
        this.alcanceRegistrado = true;
        this.porcentajeAvance += 10;
      } else {
        this.alcanceRegistrado = false;
        this.datosPendientes++;
      }

      //Datos de la seccion Datos Financieros del proyecto
      if (datosProyecto.datosFinancierosProyeccionRegistrados > 0) {
        this.datosFinancierosRegistrados = true;
        this.porcentajeAvance += 20;
      } else {
        this.datosPendientes++;
        this.datosFinancierosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.plazos > 0) {
        this.fechasPlazosRegistradas = true;
        this.porcentajeAvance += 10;
      } else {
        this.fechasPlazosRegistradas = false;
        this.datosPendientes++;
      }

      if (datosProyecto.coberturaVinculacionRegistrada > 0) {
        this.coberturaRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.coberturaRegistrada = false;
        this.datosPendientes++;
      }

      //Beneficiarios

      if (datosProyecto.cuentaBeneficiariosDirectos === 1) {
        if (datosProyecto.beneficiariosDirectos > 0) {
          this.beneficiariosRegistrados = true;
          this.porcentajeAvance += 5;
        } else {
          this.beneficiariosRegistrados = false;
          this.datosPendientes++;
        }
      } else {
        this.beneficiariosRegistrados = true;
        this.porcentajeAvance += 5;
      }

      if (datosProyecto.cuentaInstitucionesBeneficiarias === 1) {
        if (datosProyecto.institucinesBeneficiarias > 0) {
          this.institucionesBeneficiariasRegistradas = true;
          this.porcentajeAvance += 5;
        } else {
          this.institucionesBeneficiariasRegistradas = false;
          this.datosPendientes++;
        }
      } else {
        this.institucionesBeneficiariasRegistradas = true;
        this.porcentajeAvance += 5;
      }

      //Documentos
      if (datosProyecto.documentosCargados > 0) {
        this.documentosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.documentosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.contactosRegistrados > 0) {
        this.contactosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.contactosRegistrados = false;
        this.datosPendientes++;
      }

      if (this.porcentajeAvance > 100) {
        this.porcentajeAvance = 100;
      }
    },

    async confirmarProyecto() {
      this.btnEnvioLoading = true;

      await this.$store
        .dispatch(CONFIRMAR_PROYECTO_CNS, {
          id: this.idProyectoSeleccionado,
          estadoId: 2
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogConfirmacion = false;
            this.dialogRegistro = false;
            this.obtenerProyectos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnEnvioLoading = false;
        });
    },

    mostrarDialogFechas(id, fechaFin) {
      this.codigoProyecto = id;
      this.fechaFinalizacionProyecto = fechaFin;
      this.dialogFechas = true;
    },

    mostrarDialogEsperandoEstado(codigo, nombreProyecto, estado, estadoProyectoId) {
      this.codigoProy = codigo;
      this.nombreProy = nombreProyecto;
      this.estadoProy = estado;
      this.dialogEsperandoEstado = true;
      console.log(estadoProyectoId)
    },

    actualizarEjecucionSubdivisionTerritorio(proyectoId) {
      // console.log("aqui......... " + proyectoId)
      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.obtenerMontosSuscritosDepto(
          proyectoId
        );
      }
    },

    //Habilitar o deshabilitar la subsección de cobertura territorial en la sección de cobertura y vinculación
    //Tipo: 1 habilitado, 2 deshabilitado
    habilitarCoberturaTerritorial(tipo) {
      if (this.$refs.seccionCoberturaVinculacion) {
        this.$refs.seccionCoberturaVinculacion.habilitarCoberturaTerritorial(
          tipo
        );
      }
    },

    cerrarDialogLoader() {
      this.dialogLoaderVisible = false;
    },

    cuentaCondicionesDesembolsos(tipoCooperacionId) {
      let accion = 1;
      if (tipoCooperacionId != 1) {
        accion = 2;
      }

      if (this.$refs.seccionPlazos) {
        this.$refs.seccionPlazos.habilitarSeccionCondicionesDesembolsos(accion);
      }
    },

    //Actualizar el avance del proyecto
    actualizarAvanceProyecto(proyectoId) {
      if (this.$refs.SeccionConfirmarProyecto) {
        this.$refs.SeccionConfirmarProyecto.obtenerAvanceProyectoCns(
          proyectoId
        );
      }
    }
  },

  created() {
    this.currentDate = this.dateNow.format("MM/DD/YYYY");
    this.obtenerProyectos();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Proyectos" },
      { title: "CNS" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Código SIGEACI",
          align: "start",
          sortable: false,
          value: "refSigeaci"
        },
        {
          text: "Fecha opinión técnica",
          align: "start",
          sortable: true,
          value: "fechaOpinionTecnica"
        },
        {
          text: "Nombre Proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fecha de inicio y finalización",
          align: "start",
          sortable: true,
          value: "fechaInicio"
        },

        {
          text: "Unidad Ejecutora",
          align: "start",
          sortable: true,
          value: "nombreUnidadEjecutora"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyectoId",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    }
  }
};
</script>
