<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <!--inicio:: condiciones de beneficiarios -->
      <v-row>
        <v-col cols="12" md="6" sm="12" class="pt-1 pb-0">
          <v-subheader class="text-h5 black--text">
            Confirmar información
          </v-subheader>
          <p class="text-subtitle-1 pl-2" style="margin-top: -15px;">
            A continuación se muestran los datos de avance del proyecto
          </p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`"
            >
              Información General
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="
                      informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`
                    "
                    >{{
                      informacionGeneralRegistrada
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="alcanceRegistrado ? `#26c6da` : `#FFAB91`"
            >
              Alcance del proyecto
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="alcanceRegistrado ? `#26c6da` : `#FFAB91`"
                    >{{
                      alcanceRegistrado
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="datosFinancierosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Datos financieros
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="datosFinancierosRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      datosFinancierosRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="fechasPlazosRegistradas ? `#26c6da` : `#FFAB91`"
            >
              Plazos
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="fechasPlazosRegistradas ? `#26c6da` : `#FFAB91`"
                    >{{
                      fechasPlazosRegistradas
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="coberturaRegistrada ? `#26c6da` : `#FFAB91`"
            >
              Cobertura y vinculación
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="coberturaRegistrada ? `#26c6da` : `#FFAB91`"
                    >{{
                      coberturaRegistrada
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="beneficiariosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Beneficiarios
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="
                      beneficiariosRegistrados &&
                      institucionesBeneficiariasRegistradas
                        ? `#26c6da`
                        : `#FFAB91`
                    "
                    >{{
                      beneficiariosRegistrados &&
                      institucionesBeneficiariasRegistradas
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="documentosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Documentos
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="documentosRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      documentosRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" sm="6" xs="12">
          <v-card flat outlined>
            <v-card-title
              class="text-h6"
              :color="contactosRegistrados ? `#26c6da` : `#FFAB91`"
            >
              Contactos
            </v-card-title>

            <!--  <v-card-subtitle
              >Listen to your favorite artists and albums whenever and wherever,
              online and offline.</v-card-subtitle
            > -->

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <v-icon
                    x-large
                    :color="contactosRegistrados ? `#26c6da` : `#FFAB91`"
                    >{{
                      contactosRegistrados
                        ? "mdi-check-all"
                        : "mdi-alert-circle-outline"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="
          porcentajeAvance < 100 && datosAvanceProyecto.estadoProyectoId === 1
        "
      >
        <v-col cols="12" md="12">
          <v-alert outlined type="warning" color="#FF7043" border="left">
            Existe información incompleta, por favor complete los campos
            obligatorios para poder confirmar la información del proyecto.
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="datosAvanceProyecto.estadoProyectoId != 1">
        <v-col cols="12" md="12">
          <v-alert outlined type="success" color="#26c6da" border="left">
            La información del proyecto ya se encuentra confirmada!
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <v-btn
            color="light-blue-502"
            class="white--text mb-2"
            type="submit"
            x-large
            :elevation="0"
            :disabled="
              porcentajeAvance < 100 ||
                datosAvanceProyecto.estadoProyectoId != 1
            "
            :loading="btnConfirmarLoading"
            @click="confirmarProyecto"
          >
            Confirmar Información del Proyecto
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import {
  OBTENER_AVANCE_PROYECTO_CNS//,
 // CONFIRMAR_PROYECTO_CNS
} from "@/core/services/store/proyectoscns/proyectocns.module";
export default {
  name: "SeccionConfirmarProyecto",
  props: ["id", "tipoUsuario"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  components: {
    DialogLoader,
    SnackAlert
  },
  data() {
    return {
      skeletonLoading: false,
      btnEnvioLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Actualizando información del proyecto...",
      informacionGeneralRegistrada: false,
      alcanceRegistrado: false,
      datosFinancierosRegistrados: false,
      fechasPlazosRegistradas: false,
      coberturaRegistrada: false,
      beneficiariosRegistrados: false,
      institucionesBeneficiariasRegistradas: false,
      documentosRegistrados: false,
      contactosRegistrados: false,
      btnConfirmarLoading: false,
      datosAvanceProyecto: {},
      porcentajeDatosFinancieros: 0,
      porcentajeAvance: 0,
      datosPendientes: 0,
      proyectoConfirmado: false,
      codigoProyecto: ""

    };
  },

  methods: {
    ///Obener el avance de registro del proyecto
    async obtenerAvanceProyectoCns(proyectoId) {
      this.datosAvanceProyecto = {};
      this.skeletonLoading = true;
      await this.$store
        .dispatch(OBTENER_AVANCE_PROYECTO_CNS, { id: proyectoId })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosAvanceProyecto = res.data;
            this.codigoProyecto = this.datosAvanceProyecto.codigo;
            this.verificarDatosSecciones(this.datosAvanceProyecto);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Mensaje",
              res.message
            );
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.skeletonLoading = false;
          this.dialogLoaderVisible = false;
          this.datosAvanceProyecto = {};
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    verificarDatosSecciones(datosProyecto) {
      this.porcentajeDatosFinancieros = 0;
      this.porcentajeAvance = 0;

      //Datos de la seccion información General del proyecto
      if (datosProyecto.informacionGeneral > 0) {
        this.informacionGeneralRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.informacionGeneralRegistrada = true;
        this.datosPendientes++;
      }

      //Datos de la seccion alcance del proyecto
      if (datosProyecto.alcance > 0) {
        this.alcanceRegistrado = true;
        this.porcentajeAvance += 10;
      } else {
        this.alcanceRegistrado = false;
        this.datosPendientes++;
      }

      //Datos de la seccion Datos Financieros del proyecto
      if (datosProyecto.datosFinancierosProyeccionRegistrados > 0) {
        this.datosFinancierosRegistrados = true;
        this.porcentajeAvance += 20;
      } else {
        this.datosPendientes++;
        this.datosFinancierosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.plazos > 0) {
        this.fechasPlazosRegistradas = true;
        this.porcentajeAvance += 10;
      } else {
        this.fechasPlazosRegistradas = false;
        this.datosPendientes++;
      }

      if (datosProyecto.coberturaVinculacionRegistrada > 0) {
        this.coberturaRegistrada = true;
        this.porcentajeAvance += 15;
      } else {
        this.coberturaRegistrada = false;
        this.datosPendientes++;
      }

      //Beneficiarios

      if (datosProyecto.cuentaBeneficiariosDirectos === 1) {
        if (datosProyecto.beneficiariosDirectos > 0) {
          this.beneficiariosRegistrados = true;
          this.porcentajeAvance += 5;
          //console.log("beneficiarios directos " + this.porcentajeAvance)
        } else {
          this.beneficiariosRegistrados = false;
          this.datosPendientes++;
        }
      } else {
        this.beneficiariosRegistrados = true;
        this.porcentajeAvance += 5;
        //console.log("beneficiarios directos no " + this.porcentajeAvance)
      }

      if (datosProyecto.cuentaInstitucionesBeneficiarias === 1) {
        if (datosProyecto.institucinesBeneficiarias > 0) {
          this.institucionesBeneficiariasRegistradas = true;
          this.porcentajeAvance += 5;
          //console.log("instituciones beneficairias " + this.porcentajeAvance)
        } else {
          this.institucionesBeneficiariasRegistradas = false;
          this.datosPendientes++;
        }
      } else {
        this.institucionesBeneficiariasRegistradas = true;
        this.porcentajeAvance += 5;
        //console.log("instituciones beneficairias no " + this.porcentajeAvance)
      }

      //Documentos
      if (datosProyecto.documentosCargados > 0) {
        this.documentosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.documentosRegistrados = false;
        this.datosPendientes++;
      }

      if (datosProyecto.contactosRegistrados > 0) {
        this.contactosRegistrados = true;
        this.porcentajeAvance += 10;
      } else {
        this.contactosRegistrados = false;
        this.datosPendientes++;
      }
    },

    async confirmarProyecto() {
      this.$emit("mostrar-dialog-confirmar", this.id, this.codigoProyecto);
      /*this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Actualizando estado del proyecto...";
      this.btnEnvioLoading = true;

      await this.$store
        .dispatch(CONFIRMAR_PROYECTO_CNS, {
          id: this.id,
          estadoId: 2
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.obtenerAvanceProyectoCns(this.id);
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.dialogConfirmacion = false;
            this.$emit("obtener-proyectos");
            //this.obtenerProyectos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
          this.btnEnvioLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido al intentar realizar una acción, por favor, póngase en contacto con un administrador del sistema. REF::" +
              error
          );
          this.btnEnvioLoading = false;
        });*/
    }
  },

  created() {
    this.obtenerAvanceProyectoCns(this.id);
  }
};
</script>
